import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from '../layouts/layout'
import Breadcrumb from '../components/section/breadcrumb'
import * as styles from '../styles/css/about.module.css'
import { FiCheck } from 'react-icons/fi'
import Logo from '../components/logo'

const About = () => {
  const { t } = useTranslation();
  const data = {
      "title": t('aboutElementTitle'),
      "element1": t('aboutElement1'),
      "element2": t('aboutElement2'),
      "element3": t('aboutElement3'),
      "subtitle": t('aboutSubtitle'),
      "desc": t('aboutDesc')
  }
  return (
    <Layout>
      <div className="main-content">
        <div className="rwt-portfolio-details">
          <div className="rwt-split-area">
            <div className="row pb--125">
              <Breadcrumb
                title="About"
                rootUrl="/"
                parentUrl="Home"
                currentUrl="About"
                styles={styles.bg}
              />
            </div>
            <div className="container">
              <div className="wrapper">
                <div className="row row--30">
                  <div className={`col-lg-6 ${styles.leftcontain}`}>
                    <div className={`logo ${styles.logo}`}>
                      <img className="logo-light" src="/images/logo/logo.png" alt="Alphacode Logo" />
                      <img className="logo-dark" src="/images/logo/logo-dark.png" alt="Alphacode Logo" />
                    </div>
                    <div className="view-btn mt--50">
                      {/* <a className="btn-default btn-large round" href={`${data.link}`}>{data.linktext}</a> */}
                    </div>
                  </div>
                  <div className="col-lg-6 mt_md--30 mt_sm--30">
                    <div className="content-right">
                        <h5 className="subtitle">{data.subtitle}</h5>
                        <div className={`description ${styles.desctext}`} dangerouslySetInnerHTML={{__html: data.desc}}></div>
                    </div>
                    <ul className="list-icon">
                          <li className={styles.item}><span className="icon"><FiCheck /></span> {data.element1} </li>
                          <li className={styles.item}><span className="icon"><FiCheck /></span> {data.element2} </li>                          
                          <li className={styles.item}><span className="icon"><FiCheck /></span> {data.element3} </li>                          
                        </ul>
                    </div>
                    </div>
                    <div className="iframecontainer">
                    <iframe src="https://www.youtube.com/embed/QMudfV7SMqk" 
                    width="700" 
                    height="394" 
                    title="AlphaCode Introduction" 
                    frameBorder="0" 
                    allowFullScreen>
                    </iframe>
                    </div>
                  </div>                
                </div>
              </div>
            </div>
          </div>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default About
